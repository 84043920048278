import type {FilterSpec} from '@/model/common/types';
import Regexes from '@/Regexes';
import {z, type UnknownKeysParam, type ZodRawShape, type ZodTypeAny} from 'zod';

export function transformUndefinedToNull(val) {
  return val === undefined ? null : val;
}

const whitespaceRegex = /\s+/g;
export function transformMergeWhitespace(val: string) {
  return val.replace(whitespaceRegex, ' ');
}

const validateUrlPath = (path: string) => {
  try {
    // Use the WHATWG URL API to validate the pathname part of a URL
    const parsedUrl = new URL(`http://localhost${path}`);
    return parsedUrl.pathname === path;
  } catch {
    return false;
  }
};

export function zUrlPath() {
  return z.string().refine(
    (path) => {
      return validateUrlPath(path);
    },
    {
      message: 'Invalid URL path',
    },
  );
}

export const noWhitespaceUnlessLiteralSpace = z
  .string()
  .regex(Regexes.NoWhitespaceUnlessLiteralSpace);

export const transformWhitespaceIfNoLiteralSpace = z
  .string()
  .trim()
  .transform(transformMergeWhitespace);

export function multilineToArray<T>(arrayValidator: z.ZodArray<z.ZodType<T, any, any>>) {
  return z.preprocess((input) => {
    if (typeof input === 'string') {
      return input.split(/\r?\n/).filter(Boolean);
    }
    return input;
  }, arrayValidator);
}

export const href = z.string().trim().url('Invalid URL');

export const url = href;

export const money = z.coerce.number().gt(0, 'Amount must be positive');

export function createFilterSpecsValidator<T extends Record<string, string | number | boolean>>(
  filterSpecs: FilterSpec<T>[],
) {
  const validators = filterSpecs.map((spec) => {
    if (spec.type === 'in') {
      const literalValidators = spec.values.map((value) => {
        return z.literal(value);
      });
      const allowedValueValidator =
        literalValidators.length === 1
          ? literalValidators[0]
          : z.union(
              literalValidators as unknown as [z.ZodTypeAny, z.ZodTypeAny, ...z.ZodTypeAny[]],
            );

      return z.object({
        property: z.literal(spec.property),
        type: z.literal('in'),
        values: z.array(allowedValueValidator).max(literalValidators.length),
      });
    } else {
      return z.object({
        property: z.literal(spec.property),
        type: z.literal('isNull'),
        values: z.array(z.boolean()).max(2),
      });
    }
  });

  const unionSchema =
    validators.length === 1
      ? validators[0]
      : z.union(validators as unknown as [z.ZodTypeAny, z.ZodTypeAny, ...z.ZodTypeAny[]]);

  return z.array(unionSchema).optional();
}

export const list = z.object({
  searchQuery: z.string().optional(),
  isDescending: z.boolean().optional(),
  limit: z.number().max(50).optional(),
});

export const stripeId = z.string();

// TODO this doesn't work; try to fix it
// export function generatePatchFromUpdate<
//   T extends z.ZodRawShape,
//   R extends Partial<Record<keyof T, true | undefined>>,
// >(args: {update: z.ZodObject<T>; idObjRequired: R}) {
//   const {update, idObjRequired} = args;
//   return update
//     .partial()
//     .required(idObjRequired as any)
//     .refine(
//       (data) => {
//         return Object.keys(data).length > 1;
//       },
//       {
//         message: 'At least one field must be provided',
//       },
//     )
//     .transform((data) => {
//       return data as PatchForm<z.infer<typeof update>, keyof R>;
//     });
// }
